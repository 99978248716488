import React from "react";
import styles from "./poster.module.scss";

type PosterP = {
  src: string;
};

function Poster(p: PosterP) {
  const { src } = p;
  return (
    <div className={styles.poster}>
      <img className={styles.img} src={src} alt="" />
    </div>
  );
}

export default Poster;
