import React from "react";
import styles from "./content.module.scss";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

type ContentP = {
  title: string;
  p: string[];
  action: {
    text: string;
    onClk: () => void;
  };
};

function Content(p: ContentP) {
  const { title, p: aP, action } = p;
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`${styles.content} ${
        i18n.language === "zh" ? styles.zh : styles.en
      }`}
    >
      <div className={styles.title}>{t(title)}</div>
      <div className={styles.ps}>
        {aP.map((item) => {
          return (
            <p key={item} className={styles.p}>
              {t(item)}
            </p>
          );
        })}
      </div>
      <div className={styles.action}>
        <Button
          className={styles.btn}
          type="primary"
          onClick={() => {
            action.onClk?.();
          }}
        >
          {t(action.text)}
        </Button>
      </div>
    </div>
  );
}

export default Content;
