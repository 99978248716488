import React from "react";
import {
  ShareAltOutlined,
  ExperimentOutlined,
  ConsoleSqlOutlined,
  AppstoreOutlined,
  LineChartOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const cardList = [
  {
    icon: <ConsoleSqlOutlined className={styles.icon} />,
    title: "service1",
    content: "service1Text",
  },
  {
    icon: <UsergroupAddOutlined className={styles.icon} />,
    title: "service2",
    content: "service2Text",
  },
  {
    icon: <AppstoreOutlined className={styles.icon} />,
    title: "service3",
    content: "service3Text",
  },
  {
    icon: <LineChartOutlined className={styles.icon} />,
    title: "service4",
    content: "service4Text",
  },
  {
    icon: <ExperimentOutlined className={styles.icon} />,
    title: "service5",
    content: "service5Text",
  },
  {
    icon: <ShareAltOutlined className={styles.icon} />,
    title: "service6",
    content: "service6Text",
  },
];

function Serivice() {
  const { t, i18n } = useTranslation();
  return (
    <section
      id="section3"
      className={`${styles.services} ${
        i18n.language === "zh" ? styles.zh : styles.en
      }`}
    >
      <div className={styles.title}>{t("service_list")}</div>
      <div className={styles.content}>
        {cardList.map((i) => {
          return (
            <div key={i.title} className={styles.card}>
              {i.icon}
              <div className={styles.cardTitle}>{t(i.title)}</div>
              <div className={styles.cardContent}>{t(i.content)}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Serivice;
