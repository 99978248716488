import React from "react";
import styles from "./index.module.scss";
import Logo from "../../icon.jpg";
import { useTranslation } from "react-i18next";

export const sections = [
  {
    id: "section1",
    key: "product",
    title: "产品概述",
  },
  {
    id: "section2",
    key: "advantage",
    title: "产品优势",
  },
  {
    id: "section3",
    key: "service_list",
    title: "服务列表",
  },
  {
    id: "section4",
    key: "value_added_service",
    title: "增值服务",
  },
];

function Menu() {
  const { t, i18n } = useTranslation();
  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "zh" ? "en" : "zh");
  };

  const scrollTo = (el: string) => {
    const elm = document.querySelector(`#${el}`);
    // @ts-ignore
    const top = elm.offsetTop - 100;
    console.log("top", top);
    window.scrollTo({
      top: top,
      behavior: 'smooth' // 平滑滚动
    });
  };

  return (
    <div className={styles.menu}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <img className={styles.iconImg} src={Logo} alt="" />
        </div>
        <div className={styles.tabs}>
          {sections.map((s) => (
            <div
              key={s.id}
              className={styles.tab}
              onClick={() => {
                scrollTo(s.id);
              }}
            >
              {t(s.key)}
            </div>
          ))}
        </div>
        <div className={styles.switch} onClick={changeLanguage}>
          <div className={styles.llcihc}>
            <span
              className={`${styles.lan} ${
                i18n.language === "zh" ? styles.active : styles.noActive
              }`}
            >
              中
            </span>
            <span
              className={`${styles.lan} ${
                i18n.language === "en" ? styles.active : styles.noActive
              }`}
            >
              En
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
