const baseUrl = "https://linglizengzhang.tech/api";

type IRegisteP = {
  phone_number: string;
  content: string;
  email: string;
};

export const registe = (p: IRegisteP) => {
  // 发送 POST 请求
  return fetch(`${baseUrl}/registe`, {
    method: "POST", // 或者 'PUT'
    headers: {
      "Content-Type": "application/json",
      // 可以添加其他头信息
    },
    body: JSON.stringify(p), // 必须是字符串
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // 如果服务器返回的是 JSON 格式的数据
    })
};
