import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      product: "Overview",
      overviewP1:
        "FastGrowth is an end-to-end intelligent user growth solution, developed as an IT system based on AI-native architecture. Supported by the exF.A.S.T growth model and artificial intelligence technology.",
      overviewP2:
        "The exF.A.S.T model adheres to the refined user operation philosophy, performing multidimensional segmentation of product users with smart analysis of user behavior to generate user tags. Combined with operational data metrics, the system automatically identifies target audiences and provides detailed operational suggestions and support for your business.",
      overviewP3:
        "With the support of advanced AI models, FastGrowth offers multiple data analysis tools such as anomaly detection, comparative analysis, and drill-down analysis. Users can engage in interactive dialogues with the system to perform intelligent business data analysis.",
      overviewP4:
        "Equipped with advanced domain knowledge and user growth models, FastGrowth not only provides efficient analysis tools but also delivers sophisticated and effective growth recommendations during the analysis process.",
      advantage: "Advantages",
      advantageP1:
        "FastGrowth is an AI-driven IT solution featuring advanced user growth models embedded within its LLMs. It transmits the accumulated expertise to clients' businesses through its built-in growth models. Furthermore, we maintains ongoing, in-depth communication with major internet teams to continuously update the model's knowledge base and maintain its cutting-edge status.",
      advantageP2:
        "Supported by these advanced AI models, FastGrowth is more than just a traditional toolkit. It provides flexible 'conversational' interactions, offering users insightful suggestions during their experience, making it an intelligent system that interacts with users.",
      advantageP3:
        "Thanks to its AI-native technology architecture, FastGrowth imposes no restrictions on operational processes, with the AI models generating personalized workflows in real-time. Consequently, the complexity and flexibility the system handles in tasks such as data analysis, opportunity discovery, and data interpretation represent a qualitative leap over tools based on traditional IT architectures.",
      service_list: "Services",
      value_added_service: "Value-added",
      addedP1:
        'The "Fast Growth" architecture is designed modularly to support flexible customization. You can choose to fully deploy the complete "Fast Growth" to utilize all services, or opt for partial module deployment to redefine and integrate new business processes with the deployed modules. It\'s also possible to deploy certain functional modules independently and use the functionalities separately.',
      addedP2:
        'In addition to offering the "Fast Growth" we provide professional user growth consulting and training services to help businesses develop and optimize user growth strategies and enhance the user growth expertise of their teams.',
      questions: "Questions",
      service1: "Reporting System",
      service1Text:
        "The reporting system uses leading user growth models and professional experience, offering various tables, visualizations, and rich interactive features.",
      service2: "User Profiling",
      service2Text:
        "By combining user segmentation with business data and AI capabilities, we offer tools for creating user profiles and selecting audiences, helping to refine business operations.",
      service3: "Analytical Tools",
      service3Text:
        "Our intelligent analysis tools, based on user and business data models, provide flexible AIsupported interactions, acting as your smart data analyst.",
      service4: "Data Interpretation",
      service4Text:
        "The AI model analyzes large data sets, using industry expertise to identify key metrics and offer targeted insights and recommendations.",
      service5: "A/B Testing",
      service5Text:
        "The system includes essential experimental tools, like bucketing algorithms and strategy transitions, integrated with your business via SDK for cost-effective strategy testing.",
      service6: "Intelligent Marketing",
      service6Text:
        'An integrated framework for "Acquisition - Engagement - Marketing" includes smart incentives, interactive games, multi-platform distribution, and various marketing methods like App Push, SMS, and email.',
      contact: "CONTACT US",
      contactTip: 'Provide your email address and other information so that we can reply to you.',
      formTip: 'By submitting this form, you agree to receive digital marketing communications, including news, events, updates and promotional emails. You may withdraw your consent and unsubscribe from this information at any time.',
      submit: 'submit',
      company: 'Hangzhou Outside the Light Cone Tech Co., Ltd.',
    },
  },
  zh: {
    translation: {
      product: "产品概述",
      overviewP1:
        "“凌厉增长”是一套端到端智能用户增长解决方案，是基于AI原生架构研发的IT系统。在exF.A.S.T增长模型和人工智能技术的支持下凌厉增长为您的产品提供从采集到营销的端到端服务。",
      overviewP2:
        "exF.A.S.T模型秉承精细化用户运营理念，对产品用户进行多维度分层，并智能分析用户行为生成用户标签，结合经营数据指标，系统自动挖掘机会人群，为业务提供精细化运营建议和系统支持。",
      overviewP3:
        "在AI大模型支持下提供异常分析、对比分析、下钻分析等多个数据分析工具，支持用户通过对话与系统交互，对经营数据进行智能分析。",
      overviewP4:
        "AI大模型内置了先进的领域知识和用户增长模型，“凌厉增长”不仅为您提供高效的分析工具，并将在分析过程中为使用者提供先进且有效的增长建议。",
      advantageP1:
        "凌厉增长是AI驱动的IT解决方案，AI大模型内置了先进的用户增长模型。通过内置的增长模型把我团队多年在用户增长业务中积累的经验传递到客户业务中。除此之外，我团队持续与各大互联网团队保持深度沟通，持续更新模型经验，保持先进性。",
      advantageP2:
        "在AI大模型支持下，凌厉增长不仅仅是一个传统的工具集合，除了提供灵活的“对话式”交互外，还具能在用户使用过程中给用户提供启发性的建议，试一个能与用户进行交流的智能系统。",
      advantageP3:
        "仰仗AI原生的技术架构，凌厉增长不对操作流程设限，AI大模型将实时生成个性化工作流。因此系统所能处理的：数据分析、机会挖掘、数据解读等问题的复杂性和灵活性，都较传统IT架构的工具有质的提升。",
      service_list: "服务列表",
      value_added_service: "增值服务",
      addedP1:
        "“凌厉系统”架构采用模块化设计，支持灵活定制。企业既可以选择使用完整部署完整的“凌厉系统”使用全部服务；也可以选择部分模块部署，重新定制集成已部署模块的新业务流程；甚至可以独立部署部分功能模块，独立使用功能模块提供的功能。",
      addedP2:
        "除了提供“凌厉系统”外，我们还为企业提供专业的用户增长咨询和培训服务，帮助企业制定和优化用户增长战略，提升团队用户增长专业能力。",
      advantage: "产品优势",
      questions: "常见问题",
      service1: "报表体系",
      service1Text:
        "报表设计体现行业领先的用户增长模型和多年来在用户增长专业经验，具备多种类型的表格和图形可视化方案，并提供丰富的交互操作。",
      service2: "用户档案",
      service2Text:
        "通过整合用户分层&分类标签和业务经营数据，借助AI大模型能力提供用户画像和人群圈选工具。通过数据认知用户，挖掘发现机会人群，为业务精细化运营提供基础。",
      service3: "分析工具",
      service3Text:
        "基于用户域和经营域数据仓库模型的多项智能分析工具，AI支持下的对话式交互提供了最大程度使用灵活性，是您的智能数据分析师。",
      service4: "数据解读",
      service4Text:
        "AI大模型具备阅读海量数据的能力，结合模型内化的行业专业经验和实时信息，AI将在数据报表中找到关键指标，并做出针对性总结和解读，给出恰当建议。",
      service5: "A/B实验",
      service5Text:
        "系统提供包括分桶算法、实验分析、策略流转等实验核心能力，通过SDK方式与您的业务系统深度整合。用极低成本实现业务策略实验，获得业务效率最大化。",
      service6: "智能营销",
      service6Text:
        "“获客-承接-营销”一体化框架提供丰富的营销模块，包括：精细化智能权益，互动游戏玩法，多平台投放，分享引流，App Push通知，短信息和电子邮件等多种营销和触达方式。",
      contact: "联系我们",
      contactTip: '提供您的邮箱等信息，以便方便给您回信',
      formTip: '提交此表单，即表达您同意接受凌厉增长发送的电子版营销信息，包括新闻、活动、更新和推广邮件。您可随时撤销同意并退订这些资讯。',
      submit: '提交',
      company: '杭州光锥以外技术有限公司',
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "zh",
    fallbackLng: "zh",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
