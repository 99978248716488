import React from "react";
import styles from "./index.module.scss";
import Poster from "./components/poster";
import Content from "./components/content";

type SliderProp = {
  leftImg: boolean;
  src: string;
  title: string;
  p: string[];
  id: string;
  action: {
    text: string;
    onClk: () => void;
  };
};

function Slider(p: SliderProp) {
  const { leftImg, src, action, p: aP, title, id } = p;
  return (
    <section className={styles.slider} id={`${id}`}>
      <div className={`${styles.left} ${leftImg ? styles.leftImg : ""}`}>
        {leftImg ? (
          <Poster src={src}></Poster>
        ) : (
          <Content title={title} action={action} p={aP} />
        )}
      </div>
      <div className={`${styles.right} ${!leftImg ? styles.rightImg : ""}`}>
        {!leftImg ? (
          <Poster src={src}></Poster>
        ) : (
          <Content title={title} action={action} p={aP} />
        )}
      </div>
    </section>
  );
}

export default Slider;
