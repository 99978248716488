import React from "react";
import { Form, Input, Button, message } from "antd";
import styles from "./index.module.scss";
import { registe } from "../../service";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onFinish = (fieldsValue: any) => {
    console.log("ddd", fieldsValue);
    const { email, phone_number, content } = fieldsValue;
    registe({
      email,
      phone_number,
      content,
    })
      .then(() => {
        message.success("收到您的信息啦～我们会及时联系您的");
        form.resetFields();
      })
      .catch(() => {
        message.error("系统异常！");
      });
  };
  return (
    <div className={styles.footer} id="footer">
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.leftTitle}>{t("contact")}</div>
          <div className={styles.leftContent}>{t("contactTip")}</div>
        </div>
        <div className={styles.right}>
          <Form
            layout="inline"
            form={form}
            scrollToFirstError
            className={styles.form}
            name="registe"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label=""
              rules={[
                { type: "email", message: "The input is not valid E-mail" },
                { required: true, message: "Please input your E-mail" },
              ]}
            >
              <Input
                className={styles.input}
                placeholder="input your E-mail"
              ></Input>
            </Form.Item>
            <Form.Item
              name="phone_number"
              label=""
              rules={[
                { required: true, message: "Please input your phone number" },
              ]}
            >
              <Input
                placeholder="input your phone number"
                className={styles.input}
              ></Input>
            </Form.Item>
            <Form.Item
              name="content"
              label=""
              rules={[
                { required: true, message: "Please input your questions" },
              ]}
            >
              <Input.TextArea
                showCount={false}
                maxLength={200}
                placeholder="input your questions"
                style={{
                  width: "376rpx",
                  height: "80rpx",
                  marginTop: "12rpx",
                }}
                className={styles.area}
              ></Input.TextArea>
            </Form.Item>
            <div className={styles.guide}>{t("formTip")}</div>
            <Form.Item>
              <Button className={styles.btn} type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.ipc}>
        <div className={styles.reference}>
          <span>@Copyright</span>
          <span> {t("company")}</span>
        </div>
        <span className={styles.separator}></span>
        <a className={styles.reference} href="https://beian.miit.gov.cn/#/">
          <div className={styles.reference}>
            浙<span>ICP</span>
            备2024117945号-1
          </div>
        </a>
      </div>
    </div>
  );
}

export default Footer;
