import React from "react";
import Menu, { sections } from "./components/menu";
import styles from "./app.module.scss";
import Slider from "./components/slider";
import Services from "./components/services";
// import Questions from "./components/questions";
import Footer from "./components/footer";
import Bg1 from "./bg1.png";
import Bg2 from "./bg2.png";
import Bg3 from "./bg3.png";
import "./i18n";

const p1 = ["overviewP1", "overviewP2", "overviewP3", "overviewP4"];

const p2 = ["advantageP1", "advantageP2", "advantageP3"];

const p4 = ["addedP1", "addedP2"];

const scrollTo = (el: string) => {
  const form = document.querySelector(el);
  form?.scrollIntoView({
    behavior: "smooth",
  });
};

const scrollToFooter = scrollTo.bind(null, '#footer');

function App() {
  return (
    <div className={styles.home}>
      <Menu />
      <div className={styles.content}>
        <Slider
          leftImg
          p={p1}
          src={Bg1}
          title={sections[0].key}
          id={sections[0].id}
          action={{
            text: "contact",
            onClk: scrollToFooter,
          }}
        ></Slider>
        <Slider
          leftImg={false}
          p={p2}
          src={Bg2}
          title={sections[1].key}
          id={sections[1].id}
          action={{
            text: "contact",
            onClk: scrollToFooter,
          }}
        ></Slider>
        <Services />
        <Slider
          leftImg
          p={p4}
          src={Bg3}
          title={sections[3].key}
          id={sections[3].id}
          action={{
            text: "contact",
            onClk: scrollToFooter,
          }}
        ></Slider>
        {/* <Questions /> */}
      </div>
      <Footer />
    </div>
  );
}

export default App;
